import PropTypes from 'prop-types';
import React, { useContext, useMemo } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar, IconButton } from '@material-ui/core';
import { Grid } from 'src/kiska/components/Grid';
import classNames from 'classnames';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Menu as MenuIcon, Home as HomeIcon, ChevronLeft as BackIcon } from '@material-ui/icons';
import PopoverMenu from 'src/kiska/components/PopoverMenu';
import { SettingsContext } from 'src/kiska/components/contexts/SettingsContext';
import { useAuth0 } from '@auth0/auth0-react';
import { StopWorkButton } from 'src/components/modules/job/JobSwitcher/StopWorkButton';
import { ThemeMenu } from 'src/kiska/components/ThemeMenu';
import { useUser } from 'src/kiska/components/contexts/UserContext';
import { useAppSettings } from 'src/kiska/components/contexts/AppSettingsContext';
import { buildMenus } from './menus';

const styles = (theme) => ({
  root: {
    backgroundColor: theme.palette.brand.appBar,
  },
  toolbar: {
    height: 48,
    minHeight: 0,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(0, 2, 0, 0),
  },
  logo: {
    height: 28,
    opacity: 0.7,
  },
  center: {
    flex: 1,
  },
  backButton: {
    '& svg': {
      fontSize: 30,
    },
  },
  [theme.breakpoints.up('lg')]: {
    menuButton: {
      display: 'none',
    },
    toolbar: {
      padding: theme.spacing(0, 3, 0, 0),
    },
  },
});

const Header = ({ title, classes, className }) => {
  const history = useHistory();
  const location = useLocation();
  const settings = useContext(SettingsContext);
  const auth = useAuth0();
  const { user } = useUser();
  const { appSettings } = useAppSettings();
  const menus = buildMenus({ appSettings, settings, auth, history, user });

  const showThemeMenu = settings.public.devMode === true;

  const handleBackClick = () => {
    history.goBack();
  };

  return (
    <AppBar position="static" className={classNames(className, classes.root)} elevation={0}>
      <Toolbar className={classes.toolbar}>
        <div>
          <IconButton className={classes.backButton} onClick={handleBackClick} color="inherit"><BackIcon /></IconButton>
          <PopoverMenu menu={menus.main} context="mobile" trigger={<IconButton color="inherit" className={classes.menuButton}><MenuIcon /></IconButton>} />
        </div>
        <Grid container justify="center" className={classes.center}>
          <img src="/images/logo-portrait-white.svg" alt="Slick Systems logo" className={classes.logo} />
        </Grid>
        <StopWorkButton showJob={false} showLabel={false} showTime={false} style={{ marginRight: 16 }} />
        {showThemeMenu && <ThemeMenu />}
        {location.pathname !== user.preferences.homepage && <IconButton component={Link} to="/app" color="inherit"><HomeIcon /></IconButton>}
      </Toolbar>
    </AppBar>
  );
};

Header.propTypes = {
  title: PropTypes.node,
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
};

Header.defaultProps = {
  className: '',
  title: 'Slick Systems',
};

export default withStyles(styles)(Header);
