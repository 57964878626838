import React, { useMemo, forwardRef, useContext } from 'react';
import { Typography, Chip, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/styles';
import { Grid } from 'src/kiska/components/Grid';
import CustomerIcon from 'mdi-material-ui/AccountTie';
import { usePrint } from 'src/kiska/components/PdfGenerator';
import { GridLineBreak } from 'src/components/forms';
import { FormSection } from 'src/components/forms/FormSection';
import { TextField, FileField, NodeField, BooleanField, DateField, SelectField } from 'src/kiska/components/ViewOrUpdateFields';
import { useViewOrUpdate } from 'src/kiska/components/useViewOrUpdate';
import EditButton from 'src/components/updates/EditButton';
import { SimpleTable } from 'src/components/dashboards/settings/SimpleTable';
import WorkerIcon from 'mdi-material-ui/AccountHardHat';
import ChangeOrderIcon from 'mdi-material-ui/ArrowDecision';
import { AttachFile as FilesIcon } from '@material-ui/icons';
import MedicalIcon from 'mdi-material-ui/Ambulance';
import SlopeIcon from 'mdi-material-ui/SlopeUphill';
import _ from 'lodash';
import { useNodes } from 'src/kiska/hooks/useNode';
import Fixture from 'src/kiska/components/MutationContext/FormFields/Fixture';
import { useAppSettings } from 'src/kiska/components/contexts/AppSettingsContext';
import { ReportTitle } from 'src/components/dashboards/ReportTitle';
import CalendarIcon from 'mdi-material-ui/Calendar';
import SaveButton from 'src/kiska/components/MutationContext/SaveButton';
import ResourceIcon from 'mdi-material-ui/Bookshelf';
import { formatDate } from 'src/kiska/utils';
import { Link as RouterLink } from 'react-router-dom';
import { CustomerUpdateForm } from '../customer/CustomerUpdateForm';
import { Hideable } from '../../../kiska/components/Hideable';
import { useLocalNode } from '../../../kiska/components/LocalNodeContext';
import { generateShortId } from './utils';
import { ContactRenderer } from '../customer/ContactRenderer';
import { ElectricalJobForm } from './job_types/ElectricalJobForm';
import { useChangeOrderColumns } from '../form/forms/useChangeOrdersColumns';
import { FormViewDialog } from '../form/FormViewDialog';
import { forms } from '../form/forms';
import { useSafetyRecordColumns } from '../form/forms/useSafetyRecordColumns';
import { Calendar } from '../calendar/Calendar';
import { ResourceUpdateForm } from '../resource/ResourceUpdateForm';
import { ResourceViewDialog } from '../resource/ResourceViewDialog';
import { useResourceTableColumns } from '../resource/useResourceTableColumns';

export const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2, 0, 2, 0),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(2, 1, 2, 1),
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(2, 2, 2, 2),
    },
    // margin: theme.spacing(-0, -2, -2, -2),
  },
  dialogContent: {
    padding: 0,
  },
  header: {
    // marginBottom: theme.spacing(-4),
  },
  customerViewRenderer: {
    width: '100%',
  },
  listFormSectionContent: {
    padding: theme.spacing(2, 2, 2, 2),
  },
  calendarFormSectionContent: {
    padding: theme.spacing(0, 1, 2, 1),
  },
  titleUpdateWrapper: {
    padding: theme.spacing(0, 2),
  },
  tag: {
    marginRight: theme.spacing(0.5),
    height: 24,
  },
  event: {
    display: 'flex',
    padding: theme.spacing(0.5, 1),
    border: `solid 1px`,
    borderColor: theme.palette.border.strong,
    borderRadius: 4,
    margin: theme.spacing(0.5, 0, 0.5, 0),
  },
}));

const stdWidths = { xs: 12, sm: 12, md: 6, lg: 3 };

const CustomerViewRenderer = ({ node }) => {
  const { printMode } = usePrint();
  const classes = useStyles();
  const { id } = node || {};

  return (
    <div className={classes.customerViewRenderer}>
      <ContactRenderer contact={node} titleProps={{ variant: printMode ? 'body1' : 'h6' }} />
      {!printMode && (
        <Grid container justify="flex-end">
          <CustomerUpdateForm
            id={id}
            trigger={<EditButton icon={CustomerIcon} color="primary" size="small" variant="contained" style={{ marginBottom: 8 }}>Edit Customer</EditButton>}
          />
        </Grid>
      )}
    </div>
  );
};

const JobForm = forwardRef((props, ref) => {
  const { appSettings } = useAppSettings();
  const theme = useTheme();
  const classes = useStyles();
  const { view, update } = useViewOrUpdate();
  const { node } = useLocalNode();
  const job = node;
  const isNew = !node || !node.id;
  const { printMode } = usePrint();
  const initialShortId = useMemo(generateShortId, [node && node.id]);
  const { columns: changeOrderColumns } = useChangeOrderColumns({});
  const { columns: dailySafetyColumns } = useSafetyRecordColumns({});
  const { columns: resourcesColumns } = useResourceTableColumns({ showExpires: false, showCreatedAt: true, showTags: true, showUpdatedAt: true });
  const { nodes: tasks } = useNodes({ type: 'task' });
  const { nodes: events } = useNodes({
    type: 'event',
    where: {
      type: { _eq: 'job-work' },
      jobId: { _eq: node && node.id },
    },
    orderBy: [{ start: 'asc' }],
    skip: isNew,
  });

  if (!tasks.length) return null;

  const basicFieldsFilled = () => {
    return job.title && job.type && job.customer;
  };

  return (
    <div className={classes.root} ref={ref} style={{ paddingBottom: update ? '200px' : undefined }}>
      {appSettings.data.jobs.allJobsAllTasks && update && <Fixture name="tasks" value={tasks} />}

      {view && printMode && (
        <ReportTitle title={job.title} />
      )}

      {update && (
        <div className={classes.titleUpdateWrapper}>
          <TextField name="title" label="Job Title" omitFromView />
        </div>
      )}
      <br />

      {/* ***** Customer & Billing ****** */}
      <FormSection title="Customer" alwaysInitiallyExpanded={isNew} icon={CustomerIcon}>
        <Grid item xs={12}>
          <NodeField
            name="customer"
            label=""
            creatableUpdateForm={CustomerUpdateForm}
            creatableInputFieldMapping="company"
            viewRenderer={CustomerViewRenderer}
          />
        </Grid>
        <GridLineBreak />
        <Hideable dep="customer" showIfTruthy>
          <Grid item {...stdWidths}>
            <SelectField name="specs.billingInfoSameAsCustomer" label="Billing Info Same as Customer?" />
          </Grid>
          <GridLineBreak />
          <Hideable dep="specs.billingInfoSameAsCustomer" showIfEq="no">
            <Grid item {...stdWidths}>
              <TextField name="specs.billingInfo" label="Billing Info" rows={4} />
            </Grid>
          </Hideable>
          <GridLineBreak />
          <Grid item {...stdWidths}>
            <SelectField name="specs.ferryRequired" />
          </Grid>
        </Hideable>
      </FormSection>

      {/* ***** Job ****** */}
      <FormSection title="Basic Job Info" alwaysInitiallyExpanded={isNew}>
        <Grid item xs={12} sm={12} md={12} lg={6}>
          <SelectField name="type" label="Job Type" />
        </Grid>
        <Hideable show={basicFieldsFilled}>
          <Grid item {...stdWidths}>
            <TextField name="shortId" label="Work Order #" initialValue={initialShortId} />
          </Grid>
          <Grid item {...stdWidths}>
            <TextField name="specs.customerPo" label="Customer PO#" />
          </Grid>
          <GridLineBreak />
          {!appSettings.data.jobs.allJobsAllTasks && (
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <NodeField name="tasks" label="Tasks" initialValue={tasks} />
            </Grid>
          )}
          <GridLineBreak />
          <Hideable dep="type" showIfEq="welding">
            <Grid item {...stdWidths}>
              <SelectField name="specs.siteType" label="Site" />
            </Grid>
            <Grid item {...stdWidths}>
              <SelectField name="specs.material" label="Material" />
            </Grid>
            <Grid item {...stdWidths}>
              <TextField name="specs.quantity" label="Quantity" />
            </Grid>
          </Hideable>
          <GridLineBreak />
          <Hideable dep="type" showIfEq="welding">
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <SelectField name="specs.painted" label="Painted?" />
            </Grid>
            <Hideable dep="specs.painted" showIfEq="yes">
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField name="specs.paintingDetails" label="Painting details" rows={3} />
              </Grid>
            </Hideable>
          </Hideable>
        </Hideable>
      </FormSection>

      <Hideable show={basicFieldsFilled}>
        {/** ********* Schedule ********** */}
        {view && (
          <FormSection title="Scheduled Work" initiallyExpanded={true}>
            <Grid item xs={12}>
              {events.length && events.map((event) => (
                <div className={classes.event} key={event.id}>
                  <Typography variant="body1" color="textPrimary" style={{ paddingRight: 8 }}>
                    {formatDate(event.start, `EEEE MMM d, h:mmaaaaa'm`)} - {formatDate(event.end, `h:mmaaaaa'm`)}:
                  </Typography>
                  <Typography variant="body1" color="textSecondary">
                    {event.user.displayName}
                  </Typography>
                </div>
              ))}
              <br />
              <Button variant="outlined" component={RouterLink} to="/app/calendar" color="primary"><CalendarIcon /><span>Schedule more...</span></Button>
            </Grid>
          </FormSection>
        )}
        {update && (
          <FormSection title="Calendar" alwaysInitiallyExpanded={isNew} icon={CalendarIcon}>
            {isNew
              ? (
                <Grid item xs={12}>
                  <Grid container alignItems="center" direction="column">
                    <Typography variant="body1" color="textPrimary">You can schedule workers for this job after you save it.</Typography>
                    <SaveButton saveAndStay text="Save Job" />
                    <br />
                  </Grid>
                </Grid>
              )
              : (
                <Grid item xs={12}>
                  <Calendar job={job} showAddJobButton={false} showAddButton={false} />
                </Grid>
              )}
          </FormSection>
        )}

        {/* Notes */}
        <FormSection title="Notes" alwaysInitiallyExpanded={isNew}>
          <Grid item xs={12} md={12} lg={12}>
            <TextField name="specs.notes" label="General Job Notes" rows={6} />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField name="specs.bookkeeperNotes" label="Bookkeeper Notes" rows={6} />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField name="specs.officeManagerNotes" label="Office Manager Notes" rows={6} />
          </Grid>
        </FormSection>

        {/* Assignments */}
        {appSettings.data.jobs.useAssignments && !appSettings.data.jobs.useScheduling && (
          <Hideable hidden={printMode}>
            <FormSection title="Worker Assignments" alwaysInitiallyExpanded={isNew} icon={WorkerIcon}>
              <Grid item xs={12}>
                <NodeField name="assignedUsers" label="Workers Assigned to Job" />
              </Grid>
            </FormSection>
          </Hideable>
        )}

        <FormSection title={`Files (${isNew ? 0 : job.files.length})`} icon={FilesIcon}>
          <Grid item xs={12}>
            <FileField name="files" label="" />
          </Grid>
        </FormSection>

        {appSettings.data.jobs.useResources && (
          <FormSection title={`Resources (${isNew ? 0 : job.resources_aggregate.aggregate.count})`} icon={ResourceIcon}>
            <Grid item xs={12}>
              {update
                ? (
                  <NodeField
                    name="resources"
                    label=""
                    searchFields={[]}
                    where={(searchStr) => {
                      const fixedAnd = [
                        { userId: { _is_null: true } },
                      ];
                      if (!searchStr) {
                        return {
                          _and: fixedAnd,
                        };
                      }
                      const strs = searchStr.split(/\s/);
                      const where = {
                        _and: [
                          ...fixedAnd,
                          ...strs.map((str) => ({
                            _or: [
                              { title: { _ilike: `%${str}%` } },
                              { description: { _ilike: `%${str}%` } },
                              { tags: { tag: { label: { _ilike: `%${str}%` } } } },
                            ],
                          })),
                        ],
                      };
                      return where;
                    }}
                    renderOption={(option) => {
                      return (
                        <div className={classes.resourceOption}>
                          <Typography variant="body1" color="textPrimary">{option.title}</Typography>
                          <Typography variant="body2" color="textSecondary">{option.description}</Typography>
                          <div className={classes.tags}>
                            {option.tags && option.tags.map(({ tag }) => (
                              <Chip key={tag.id} className={classes.tag} label={tag.label} />
                            ))}
                          </div>
                        </div>
                      );
                    }}
                  />
                )
                : (
                  <SimpleTable
                    type="resource"
                    addLabel="Add New Resource"
                    columns={resourcesColumns}
                    UpdateForm={ResourceUpdateForm}
                    updateFormProps={{ showExpires: false }}
                    updateFixtures={{}}
                    insertFixtures={{ jobs: [job] }}
                    fixtures={[
                      { path: 'where.jobs.job.id._eq', value: job.id },
                    ]}
                    actionsOnBottom={false}
                    actionsOnTop={false}
                    floatingAdd
                    enableAdd={false}
                    enableEdit={false}
                    showSearchBox={false}
                    rowClickAction="view"
                    ViewDialog={ResourceViewDialog}
                    viewDialogProps={{ updateFormProps: { fixtures: {}, showExpires: false } }}
                  />
                )}
            </Grid>
          </FormSection>
        )}

        {appSettings.data.jobs.useDailySafetyRecords && view && !isNew && !printMode && (
          <FormSection
            title="Daily Safety Records"
            initiallyExpanded={false}
            classes={{ content: classes.listFormSectionContent }}
            icon={MedicalIcon}
          >
            <SimpleTable
              type="form"
              columns={dailySafetyColumns}
              UpdateForm={forms.DailySafetyForm_v1.UpdateForm}
              updateFixtures={{ jobs: [{ id: job.id }] }}
              insertFixtures={{ jobs: [{ id: job.id }] }}
              fixtures={[
                { path: 'where.jobs.job.id._eq', value: job.id },
                { path: 'where.name._eq', value: 'DailySafetyForm' },
              ]}
              rowClickAction="view"
              ViewDialog={FormViewDialog}
              viewDialogProps={{}}
            />
          </FormSection>
        )}
        {appSettings.data.jobs.useChangeOrders && view && !isNew && !printMode && (
          <FormSection
            title={`Change Orders (${isNew ? 0 : job.change_orders_aggregate.aggregate.count})`}
            initiallyExpanded={false}
            classes={{ content: classes.listFormSectionContent }}
            icon={ChangeOrderIcon}
          >
            <SimpleTable
              type="form"
              columns={changeOrderColumns}
              UpdateForm={forms.ChangeOrderForm_v1.UpdateForm}
              updateFixtures={{ jobs: [{ id: job.id }] }}
              insertFixtures={{ jobs: [{ id: job.id }] }}
              fixtures={[
                { path: 'where.jobs.job.id._eq', value: job.id },
                { path: 'where.name._eq', value: 'ChangeOrderForm' },
              ]}
              rowClickAction="view"
              ViewDialog={FormViewDialog}
              viewDialogProps={{}}
            />
          </FormSection>
        )}
      </Hideable>

      {/* </Hideable> */}
    </div>
  );
});

JobForm.displayName = 'JobForm';

export { JobForm };
